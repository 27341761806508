import { Row, Col, Button, Modal } from "reactstrap";
import { useState, useContext, useEffect } from "react";
import DateLabel from "../../components/Widgets/DateLabel";
import AdminContext from "../../AdminContext";
import MoneyLabel from "../../components/Widgets/MoneyLabel";
import LoadingSpinner from "../../components/Widgets/LoadingSpinner";
import DateTimeLabel from "../../components/Widgets/DateTimeLabel";
import qs from "qs";
import { Capacitor } from "@capacitor/core";
import axios from "axios";
import SingleSelectListWidget from "../../components/Widgets/SingleSelectListWidget";
import ComboBox from "../../components/Widgets/ComboBox";
import CheckBox from "@mui/material/Checkbox";
import EditPaySuper from "../../views/Pays/EditPayViews/EditPaySuper";
import EditPayTax from "../../views/Pays/EditPayViews/EditPayTax";
import EditPayAllowances from "../../views/Pays/EditPayViews/EditPayAllowances";
import EditPayDeductions from "../../views/Pays/EditPayViews/EditPayDeductions";
import EditPayBonuses from "../../views/Pays/EditPayViews/EditPayBonuses";
import EditPayBackPayments from "../../views/Pays/EditPayViews/EditPayBackPayments";
import EditPayDepartments from "../../views/Pays/EditPayViews/EditPayDepartments";
import EditPaySacrifices from "../../views/Pays/EditPayViews/EditPaySacrifices";
import EditPayLeaveBalances from "../../views/Pays/EditPayViews/EditPayLeaveBalances";
import EditPayLeaveTaken from "../../views/Pays/EditPayViews/EditPayLeaveTaken";
import EditPayHours from "../../views/Pays/EditPayViews/EditPayHours";
import MultiplierEdit from "../../components/Widgets/MultiplierEdit";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import EditPayslipInfo from "../../views/Pays/EditPayViews/EditPayslipInfo";
import TerminationWizard from "../../views/Pays/EditPayViews/TerminationWizard/TerminationWizard";
import PostCompleteModal from "./PostCompleteModal";

const EditPayView = (props) => {
  const TIME_ORDINARY = "Ordinary Time";
  const TIME_AND_HALF = "Time and a half";
  const TIME_DOUBLE = "Double time";

  const adminContext = useContext(AdminContext);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [currentPayID, setCurrentPayID] = useState(false);
  const [selectedView, setSelectedView] = useState("hours");
  const [editingPayslipInfo, setEditingPayslipInfo] = useState(false);
  const [terminating, setTerminating] = useState(false);
  const [postCompletePromptMessages, setPostCompletePromptMessages] = useState(
    []
  );
  const [currentPostCompleteMessageIndex, setCurrentPostCompleteMessageIndex] =
    useState(0);

  const [selectedRows, setSelectedRows] = useState({
    sacrifices: [],
  });

  // Save the initial pay details in case we need to revert
  const [initialPay, setInitialPay] = useState(false);

  const [redirectUrl, setRedirectUrl] = useState(false);
  const [terminationPreview, setTerminationPreview] = useState("");

  const removeTermination = () => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      adminContext.deleteRequest(
        adminContext.constants.BASE_URL +
          `/pays/remove-termination/${currentPayID}`,
        (response) => {
          setLoading(false);
          resolve(response); // Resolve the promise when the operation succeeds
        },
        (error) => {
          setLoading(false);
          reject(error); // Reject the promise in case of an error
        }
      );
    });
  };

  const downloadTerminationPreview = () => {
    setLoading(true);
    adminContext.getRequest(
      adminContext.constants.BASE_URL +
        `/pays/termination-preview/${currentPayID}`,
      (response) => {
        const pdfB64Data =
          "data:application/pdf;base64," + response.data.pdf_file_data;

        if (Capacitor.isNativePlatform()) {
          // Handle PDF download on mobile (Capacitor)
          const downloadPDF = async () => {
            const fileName = props.name + ".pdf";
            await adminContext.handlePDFDownload(fileName, pdfB64Data);
          };
          downloadPDF().finally(() => setLoading(false));
        } else {
          // Use setTerminationPreview for the web browser
          setTerminationPreview(pdfB64Data);
          setLoading(false);
        }
      }
    );
  };

  const downloadPDF = async () => {
    // Create an appropriate filename for termination_preview_[today's date]]_[employee_name].pdf
    const fileName = `termination_preview_${new Date().toLocaleDateString()}_${adminContext.pay.employee.format_name_shorter.replace(
      " ",
      "_"
    )}.pdf`;
    await adminContext.handlePDFDownload(fileName, terminationPreview);
  };

  useEffect(() => {
    if (terminationPreview) {
      downloadPDF();
    }
  }, [terminationPreview]);

  useEffect(() => {
    if (
      adminContext?.pay?.employee?.is_terminated !== undefined &&
      adminContext?.pay?.employee?.id
    ) {
      adminContext.setCompany((prevCompany) => {
        if (!prevCompany?.employees) return prevCompany;

        return {
          ...prevCompany,
          employees: prevCompany.employees.map((emp) =>
            emp.id === adminContext.pay.employee.id
              ? {
                  ...emp,
                  is_terminated: adminContext.pay.employee.is_terminated,
                  termination_date: adminContext.pay.employee.termination_date,
                }
              : emp
          ),
        };
      });
    }
  }, [adminContext?.pay?.employee?.is_terminated]);

  const initView = async () => {
    setLoading(true);
    // Get initial data
    adminContext.getRequest(
      adminContext.constants.BASE_URL + `/pays/pay/${currentPayID}`,
      (response) => {
        setInitialPay(response.data.pay);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (currentPayID) {
      initView();
    }
  }, [currentPayID]);

  const refreshPayId = () => {
    let payID = qs.parse(adminContext.location.search, {
      ignoreQueryPrefix: true,
    }).id;
    setCurrentPayID(payID);
  };

  useEffect(() => {
    refreshPayId();
  }, [adminContext.location]);

  const editPayNavRows = [
    {
      id: "tax",
      format_name: "Tax",
      icon: "fa-regular fa-calculator fa-xl",
    },
    {
      id: "super",
      format_name: "Super",
      icon: "fa-regular fa-hand-holding-seedling fa-xl",
    },
    {
      id: "leave_balances",
      format_name: "Leave Balances",
      icon: "fa-regular fa-plane fa-xl",
    },
    {
      id: "departments",
      format_name: "Departments",
      icon: "fa-regular fa-chart-pie fa-xl",
    },
    {
      id: "hours",
      format_name: "Hours/Pay Rates",
      icon: "fa-regular fa-clock fa-xl",
    },
    {
      id: "leave_taken",
      format_name: "Leave Taken",
      icon: "fa-regular fa-plane fa-xl",
    },

    {
      id: "allowances",
      format_name: "Allowances",
      icon: "fa-regular fa-plus fa-xl",
    },
    {
      id: "sacrifices",
      format_name: "Sacrifices",
      icon: "fa-regular fa-hand-holding-dollar fa-xl",
    },

    {
      id: "deductions",
      format_name: "Deductions",
      icon: "fa-regular fa-minus fa-xl",
    },

    {
      id: "bonus_commission",
      format_name: "Bonus/Commission",
      icon: "fa-regular fa-coins fa-xl",
    },
    {
      id: "back_payments",
      format_name: "Back Payments",
      icon: "fa-regular fa-clock-rotate-left fa-xl",
    },
  ];

  const editPayNavComboData = () => {
    let comboData = {};
    editPayNavRows.forEach((row) => {
      comboData[row.id] = row.format_name;
    });
    return comboData;
  };

  const getPayRatesComboData = () => {
    let payRates = {};
    if (
      !adminContext.pay ||
      adminContext.currentEmployee.pay_rates === undefined
    ) {
      return payRates;
    }

    // Find the employee and return all of their pay rates
    let employee = adminContext.currentEmployee;
    if (Number(employee.id) === Number(adminContext.pay.employee.id)) {
      payRates[TIME_ORDINARY] =
        TIME_ORDINARY +
        " ($" +
        Number(employee.pay_rate_per_hour._decimal).toFixed(5) +
        ")";
      payRates[TIME_AND_HALF] =
        TIME_AND_HALF +
        " ($" +
        Number(employee.pay_rate_per_hour._decimal * 1.5).toFixed(5) +
        ")";
      payRates[TIME_DOUBLE] =
        TIME_DOUBLE +
        " ($" +
        Number(employee.pay_rate_per_hour._decimal * 2).toFixed(5) +
        ")";

      employee.pay_rates.forEach((payRate) => {
        payRates[payRate.description] =
          payRate.description +
          " ($" +
          Number(payRate.prepped_amount_per_unit._decimal).toFixed(5) +
          ")";
      });

      employee.company_pay_rates.forEach((payRate) => {
        payRates[payRate.description] =
          payRate.description +
          " ($" +
          Number(payRate.prepped_amount_per_unit._decimal).toFixed(5) +
          ")";
      });
    }

    // Finally, check if any used pay rates on this pay aren't already in the list (possibly due to legacy or overridden rates)
    adminContext.pay.hours.forEach((hour) => {
      const rateValue = Number(hour.rate._decimal).toFixed(5);
      let label = `${hour.description} ($${rateValue})`;

      const existingDescriptions = Object.values(payRates).map(
        (item) => item.split(" ($")[0]
      );

      if (!existingDescriptions.includes(hour.description)) {
        payRates[label] = label;
      } else {
        // Only add it if the rate amount is new as well
        if (
          !Object.values(payRates).some(
            (item) =>
              item.split(" ($")[0] == hour.description &&
              item.includes(rateValue)
          )
        ) {
          // If the description already exists but with a different rate, add it with an asterisk
          label = "*" + label;
          payRates[label] = label;
        }
      }
    });

    return payRates;
  };

  const sanitisedRateDescription = (description) => {
    // Sanitise the description as we've had to make it unique in the combo box, remove the rate string
    description = description.split(" ($")[0];

    // Also remove the potential asterisk at the start
    if (description.startsWith("*")) {
      description = description.substring(1);
    }

    return description;
  };

  const getRate = (row) => {
    const comboOptions = getPayRatesComboData();
    const targetDescription = row.description;
    const targetRateString = `($${row.rate._decimal.toFixed(5)})`;

    for (const label of Object.values(comboOptions)) {
      if (
        sanitisedRateDescription(label) == targetDescription &&
        label.includes(targetRateString)
      ) {
        return row.rate._decimal;
      }
    }

    // Fallback
    // Find the first rate to match by description only split before " ($"
    const rateString = row.description.split(" ($")[0];
    for (const [key, label] of Object.entries(comboOptions)) {
      const labelSplit = label.split(" ($")[0];
      if (labelSplit == rateString) {
        // Return the rate from the found item
        const rate = label.split(" ($")[1].replace(")", "");
        return Number(rate);
      }
    }
    console.warn("Rate not matched by description + amount:", row);
    return row.rate._decimal;
  };

  const setPayCallback = (row) => {
    // Given an hours row, update the pay's hours, overwriting the existing row
    let hours = adminContext.pay.hours;

    hours.forEach((hour, index) => {
      if (hour.id === row.id) {
        row.rate._decimal = getRate(row);

        // Sanitise the description as we've had to make it unique in the combo box, remove the rate string
        row.description = sanitisedRateDescription(row.description);

        // Next we must cycle through adminContext.currentEmployee.pay_rates and adminContext.currentEmployee.company_pay_rates
        // to find a match from the row's description if they need to be treated as overtime

        // First check system rates
        if (row.description === TIME_ORDINARY) {
          row.is_overtime = false;
        } else if (row.description === TIME_AND_HALF) {
          row.is_overtime = true;
        } else if (row.description === TIME_DOUBLE) {
          row.is_overtime = true;
        } else {
          adminContext.currentEmployee.pay_rates.forEach((payRate) => {
            if (payRate.description === row.description) {
              row.is_overtime = payRate.is_overtime;
              row.units = payRate.units;
            }
          });
          adminContext.currentEmployee.company_pay_rates.forEach((payRate) => {
            if (payRate.description === row.description) {
              row.is_overtime = payRate.is_overtime;
              row.units = payRate.units;
            }
          });
        }

        // Update only the keys that are present in row
        Object.assign(hours[index], row);
      }
    });
    setSaving(true);
    adminContext.setPay({
      ...adminContext.pay,
      hours: hours,
    });
  };

  const hoursTotals = () => {
    let total = 0;
    adminContext.pay.hours.forEach((hour) => {
      // If 'hour' is in hour.units, use time * rate, otherwise use amount * rate
      if (hour.units === "Hours") {
        total +=
          hour.rate._decimal *
          (hour.time / adminContext.constants.MILLISECONDS_PER_HOUR);
      } else {
        total += hour.rate._decimal * hour.amount;
      }
    });
    return total;
  };

  const getLeaveLoadingAmount = (row) => {
    if (row.leave_loading_manually_edited === true) {
      return Number(row.leave_loading_amount._decimal);
    }
    let result =
      (row.time_taken.milliseconds /
        adminContext.constants.MILLISECONDS_PER_HOUR) *
      Number(row.rate._decimal) *
      row.leave_loading;
    return result.toFixed(2);
  };

  const getLeaveAmount = (row) => {
    let result =
      (row.time_taken.milliseconds /
        adminContext.constants.MILLISECONDS_PER_HOUR) *
      Number(row.rate._decimal);
    return Number(result).toFixed(2);
  };

  const getTotalAmount = (row) => {
    let totalAmount =
      Number(getLeaveAmount(row)) + Number(getLeaveLoadingAmount(row));
    return totalAmount || 0;
  };

  const leaveTotals = () => {
    let total = 0.0;
    adminContext.pay.leave.forEach((leave) => {
      total += Number(getLeaveAmount(leave));
    });
    return total;
  };

  const handlePostCompleteModalNext = () => {
    if (
      currentPostCompleteMessageIndex <
      postCompletePromptMessages.length - 1
    ) {
      // Move to the next message
      setCurrentPostCompleteMessageIndex((prevIndex) => prevIndex + 1);
    } else {
      // No more messages, handle the redirect and reset the message index
      handlePostCompleteRedirectOrLoading();
      setCurrentPostCompleteMessageIndex(0);

      // Clear the messages
      setPostCompletePromptMessages([]);
    }
  };

  const handlePostCompleteRedirectOrLoading = () => {
    if (redirectUrl) {
      adminContext.history.push(redirectUrl);
      setRedirectUrl(false);
    } else {
      setLoading(false);
    }
  };

  const updateBackendPay = (route = "", cancelTokenSource) => {
    setLoading(true);
    let updateUrl = adminContext.constants.BASE_URL + "/pays/";
    if (route !== "") {
      updateUrl += "?route=" + route;
    }
    adminContext.putRequest(
      updateUrl,
      adminContext.pay,
      (response) => {
        setLoading(false);
        if (
          response.data.post_complete_prompt_messages !== undefined &&
          response.data.post_complete_prompt_messages.length > 0
        ) {
          setPostCompletePromptMessages(
            response.data.post_complete_prompt_messages
          );
        } else {
          handlePostCompleteRedirectOrLoading();
        }
      },
      cancelTokenSource
    );
  };

  useEffect(() => {
    // Create a cancellation source
    const source = axios.CancelToken.source();

    if (saving) {
      updateBackendPay("", source);
      setSaving(false);
    }

    // Cleanup function: Cancel ongoing request if component unmounts
    return () => {
      source.cancel("Component unmounted, cancelling request");
    };
  }, [adminContext.pay]);

  const unsetCurrentPay = async () => {
    try {
      if (!adminContext.editAccess("pays")) {
        adminContext.history.goBack();
      } else {
        await removeTermination();

        setLoading(true);
        setSaving(true);
        setRedirectUrl("/admin/pays");
        adminContext.setPay({
          ...adminContext.pay,
          ...initialPay,
        });
      }
    } catch (error) {
      console.error("Error removing termination:", error);
    }
  };

  const saveAndClose = () => {
    setLoading(true);
    setSaving(true);
    setRedirectUrl("/admin/pays");
    adminContext.setPay({
      ...adminContext.pay,
      mark_complete: true,
    });
  };

  const saveAndMove = (direction) => {
    setLoading(true);
    // Rewrite saveAndNext and saveAndPrevious to use this function
    // Loop through adminContext.payRows and find the id of the pay before or after the current pay in the array
    let newPayID = false;
    adminContext.payRows.forEach((pay, index) => {
      if (pay.id === adminContext.pay.id) {
        if (adminContext.payRows[index + direction]) {
          newPayID = adminContext.payRows[index + direction].id;
        }
      }
    });
    setSaving(true);
    if (newPayID) {
      setRedirectUrl("/admin/pays/edit_pay?id=" + newPayID);
    } else {
      setRedirectUrl("/admin/pays");
    }

    adminContext.setPay({
      ...adminContext.pay,
      mark_complete: true,
    });
  };

  const createHoursRow = () => {
    let newHours = {
      id: null,
      description: TIME_ORDINARY,
      time: 0,
      rate: adminContext.pay.employee.pay_rate_per_hour,
      units: "Hours",
      is_overtime: false,
      pay_id: adminContext.pay.id,
    };

    setSaving(true);
    // Add the new hours to the current pay
    adminContext.setPay({
      ...adminContext.pay,
      hours: [...adminContext.pay.hours, newHours],
    });
  };

  const deleteHoursRow = (row) => {
    let newHours = [];
    adminContext.pay.hours.forEach((hour, index) => {
      if (hour.id !== row.id) {
        newHours.push(hour);
      }
    });
    setSaving(true);
    adminContext.setPay({
      ...adminContext.pay,
      hours: newHours,
    });
  };

  const getSortedHours = () => {
    let sortedHours = adminContext.pay.hours;
    sortedHours.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });
    return sortedHours;
  };

  const getBonusTaxMethodComboData = () => {
    return {
      NORMAL_EARNINGS: "Normal Earnings",
      METHOD_B2: "Method B (ii) (Whole Year)",
      METHOD_A_WHOLE_YEAR: "Method A (Whole Year)",
      METHOD_A_SPECIFIC_RANGE: "Method A (Use Date Range)",
    };
  };

  const getBonusSacrificeList = () => {
    let results = { sacrifices: [] };
    adminContext.pay.pay_sacrifices.forEach((sacrifice) => {
      results.sacrifices.push({
        id: sacrifice.id,
        format_name: sacrifice.description,
      });
    });
    return results;
  };

  const saveCollapsedIcons = (val) => {
    setLoading(true);

    adminContext.postRequest(
      adminContext.constants.BASE_URL + `/tools/app-config-item`,
      {
        key: "collapse_pay_buttons_on_web",
        value: val,
      },
      (response) => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      {loading || !adminContext.pay ? (
        <LoadingSpinner />
      ) : (
        <div className="mx-4 my-3">
          {!terminating && (
            <Row>
              <Col lg="7" md="12">
                <>
                  <h1>
                    Edit {adminContext.pay.pay_period.toLowerCase()} pay ending{" "}
                    <DateLabel
                      className="font-weight-bold"
                      value={adminContext.pay.date}
                    />{" "}
                    for{" "}
                    <strong>
                      {adminContext.pay.employee_format_name_shorter}
                    </strong>
                    {adminContext.pay.employee.locked ? (
                      <>
                        {" "}
                        <i className="fa-regular fa-lock text-red"></i>
                      </>
                    ) : null}
                  </h1>
                  {adminContext.pay.employee.locked ? (
                    <>
                      <p className="text-center">
                        <span>
                          This employee is <strong>locked</strong> and in
                          read-only mode. Please{" "}
                          <a href="https://www.lightningpayroll.com.au/faq?selected=4">
                            upgrade your subscription
                          </a>{" "}
                          (or terminate another employee) to unlock this
                          employee.
                        </span>
                      </p>
                      <p className="text-center text-danger">
                        Currently using {adminContext.numberActiveEmployees} out
                        of {adminContext.maxAllowedEmployees} total employees.
                      </p>
                    </>
                  ) : null}
                  {adminContext.pay.nullable_processed_date ? (
                    <p className="text-left">
                      Processed on{" "}
                      <DateLabel
                        className="font-weight-bold"
                        value={adminContext.pay.nullable_processed_date}
                      />
                      {adminContext.pay.termination ? (
                        <span className="text-danger"> - Termination Pay</span>
                      ) : null}
                    </p>
                  ) : null}
                </>
              </Col>
              <Col lg="5" md="12" className="mb-4 mt-1">
                <Row>
                  <Col>
                    <strong>Timestamp: </strong>
                    <DateTimeLabel value={adminContext.pay.timestamp} />
                  </Col>
                  {adminContext.pay.hours &&
                  adminContext.pay.hours.length > 0 ? (
                    <Col>
                      <strong>Hours Worked: </strong>
                      {adminContext.pay.hours
                        .reduce(
                          (acc, hour) => acc + hour.time / (1000 * 60 * 60),
                          0
                        )
                        .toFixed(2)}{" "}
                    </Col>
                  ) : null}
                  {adminContext.pay.leave &&
                  adminContext.pay.leave.length > 0 ? (
                    <Col>
                      <strong>Leave Hours: </strong>
                      {adminContext.pay.leave
                        .reduce(
                          (acc, leave) =>
                            acc +
                            leave.time_taken.milliseconds / (1000 * 60 * 60),
                          0
                        )
                        .toFixed(2)}{" "}
                    </Col>
                  ) : null}
                  <Col>
                    <strong>Total Hours: </strong>
                    {Number(
                      ((adminContext.pay.hours
                        ? adminContext.pay.hours.reduce(
                            (acc, hour) => acc + hour.time,
                            0
                          )
                        : 0) +
                        (adminContext.pay.leave
                          ? adminContext.pay.leave.reduce(
                              (acc, leave) =>
                                acc + leave.time_taken.milliseconds,
                              0
                            )
                          : 0)) /
                        (1000 * 60 * 60)
                    ).toFixed(2)}{" "}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {editingPayslipInfo ? (
            <Row>
              <Col>
                <EditPayslipInfo
                  cancelCallback={() => setEditingPayslipInfo(false)}
                  saveChanges={() => {
                    updateBackendPay("payslip_info");
                    setEditingPayslipInfo(false);
                  }}
                />
              </Col>
            </Row>
          ) : terminating ? (
            <>
              <h1 className="days-one">Termination Wizard</h1>
              <TerminationWizard
                cancelCallback={() => {
                  setTerminating(false);
                }}
              />
            </>
          ) : (
            <Row>
              <Col md="2">
                <label className="form-label d-block d-sm-none">
                  Section Select
                </label>
                <ComboBox
                  className="mx-3 d-block d-sm-none"
                  label=""
                  skipSort={true}
                  stretch={true}
                  name="section_selected"
                  comboData={editPayNavComboData()}
                  selectedComboItem={selectedView}
                  setSelectedComboItem={(data) => {
                    let navId = adminContext.getKeyByValue(
                      editPayNavComboData(),
                      data
                    );
                    setSelectedView(navId);
                  }}
                />
                <SingleSelectListWidget
                  noSort={true}
                  className="d-none d-sm-block"
                  showIconsOnlyButton={adminContext.editAccess("pays")}
                  setIconsCallback={saveCollapsedIcons}
                  rows={editPayNavRows}
                  dividerIndices={[4]}
                  infoLabels={{ 0: "General", 4: "Pay Items" }}
                  selectedItem={selectedView}
                  setSelectedItem={(e) => {
                    setSelectedView(e.currentTarget.id);
                  }}
                />
              </Col>
              <Col md="7">
                <fieldset
                  disabled={
                    adminContext.pay.employee.locked ||
                    !adminContext.editAccess("pays")
                  }
                >
                  <Row>
                    <Col>
                      {selectedView === "hours" ? (
                        <EditPayHours
                          adminContext={adminContext}
                          getSortedHours={getSortedHours}
                          createHoursRow={createHoursRow}
                          getPayRatesComboData={getPayRatesComboData}
                          setPayCallback={setPayCallback}
                          deleteHoursRow={deleteHoursRow}
                        />
                      ) : null}
                      {selectedView === "leave_taken" ? (
                        <EditPayLeaveTaken
                          adminContext={adminContext}
                          setSaving={setSaving}
                          getLeaveAmount={getLeaveAmount}
                          getLeaveLoadingAmount={getLeaveLoadingAmount}
                          getTotalAmount={getTotalAmount}
                        />
                      ) : null}
                      {selectedView === "leave_balances" ? (
                        <EditPayLeaveBalances
                          adminContext={adminContext}
                          updateBackendPay={updateBackendPay} // Leave balances are saved directly to the backend using the route
                        />
                      ) : null}
                      {selectedView === "bonus_commission" ? (
                        <EditPayBonuses
                          adminContext={adminContext}
                          setSaving={setSaving}
                          getBonusTaxMethodComboData={
                            getBonusTaxMethodComboData
                          }
                          getBonusSacrificeList={getBonusSacrificeList}
                          selectedRows={selectedRows}
                          setSelectedRows={setSelectedRows}
                        />
                      ) : null}
                      {selectedView === "departments" ? (
                        <EditPayDepartments
                          adminContext={adminContext}
                          setSaving={setSaving}
                        />
                      ) : null}

                      {selectedView === "allowances" ? (
                        <EditPayAllowances
                          adminContext={adminContext}
                          setSaving={setSaving}
                        />
                      ) : null}
                      {selectedView === "deductions" ? (
                        <EditPayDeductions
                          adminContext={adminContext}
                          setSaving={setSaving}
                        />
                      ) : null}
                      {selectedView === "sacrifices" ? (
                        <EditPaySacrifices
                          adminContext={adminContext}
                          setSaving={setSaving}
                        />
                      ) : null}
                      {selectedView === "back_payments" ? (
                        <EditPayBackPayments
                          adminContext={adminContext}
                          setSaving={setSaving}
                        />
                      ) : null}
                      {selectedView === "tax" ? (
                        <EditPayTax
                          adminContext={adminContext}
                          setSaving={setSaving}
                        />
                      ) : null}
                      {selectedView === "super" ? (
                        <EditPaySuper
                          adminContext={adminContext}
                          setSaving={setSaving}
                        />
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="btn my-1 float-right width-100 semi-active-button min-height-80"
                        onClick={() => {
                          setTerminating(true);
                        }}
                        color="secondary"
                      >
                        {adminContext.pay.termination ? (
                          <>
                            <i className="fa-regular fa-xl mx-auto fa-edit" />
                            <br /> Edit termination <br />
                          </>
                        ) : (
                          <>
                            <i className="fa-regular fa-xl mx-auto fa-user-minus" />
                            <br /> Terminate Employee <br />
                          </>
                        )}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        className="btn my-1 float-right width-100 semi-active-button min-height-80"
                        onClick={() => {
                          setEditingPayslipInfo(true);
                        }}
                        color="secondary"
                      >
                        <i className="fa-regular fa-xl mx-auto fa-envelope-open-dollar" />
                        <br /> Payslip Info <br />
                      </Button>
                    </Col>
                  </Row>
                  {adminContext.pay.termination ? (
                    <Row>
                      <Col>
                        <Button
                          className="btn my-1 float-right width-100 semi-active-button min-height-80"
                          onClick={() => {
                            downloadTerminationPreview();
                          }}
                          color="secondary"
                        >
                          <i className="fa-regular fa-xl mx-auto fa-download" />
                          <br /> Print/Preview <br />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className="btn my-1 float-right width-100 semi-active-button min-height-80"
                          onClick={async () => {
                            try {
                              await removeTermination();
                              console.log("Termination removed successfully");
                            } catch (error) {
                              console.error(
                                "Failed to remove termination:",
                                error
                              );
                            }
                          }}
                          color="secondary"
                        >
                          <i className="fa-regular fa-xl mx-auto fa-trash text-danger" />
                          <br /> Remove termination <br />
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col className="text-center font-weight-bold  col-md-4">
                      <Button
                        className="btn float-right my-1  width-100 min-height-80 semi-active-button"
                        onClick={() => saveAndMove(-1)}
                        color="primary"
                      >
                        <i className="fa-regular fa-xl mx-auto fa-arrow-circle-left" />
                        <br /> Save & Previous
                      </Button>
                    </Col>
                    <Col className="text-center font-weight-bold col-md-4">
                      <Button
                        className="btn  float-right width-100 my-1 min-height-80 semi-active-button"
                        onClick={() => saveAndMove(1)}
                        color="primary"
                      >
                        <i className="fa-regular fa-xl mx-auto fa-arrow-circle-right" />
                        <br /> Save & Next
                      </Button>
                    </Col>
                    <Col className="text-center font-weight-bold col-md-4">
                      <Button
                        className="btn float-right width-100 my-1 min-height-80 semi-active-button"
                        onClick={saveAndClose}
                        color="primary"
                      >
                        <i className="fa-regular fa-xl mx-auto fa-save" />
                        <br /> Save & Close
                      </Button>
                    </Col>
                  </Row>
                </fieldset>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button
                      className="btn my-1 float-right width-80-on-lg semi-active-button "
                      onClick={async () => {
                        try {
                          await unsetCurrentPay();
                          console.log("Current pay unset successfully");
                        } catch (error) {
                          console.error("Failed to unset current pay:", error);
                        }
                      }}
                      color="secondary"
                    >
                      <i className="fa-regular fa-xl mx-auto fa-times-circle" />
                      <br /> Cancel <br />
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md="3">
                <>
                  <Row>
                    <Col className="text-center font-weight-bold mt-3">
                      <h2 className="days-one">Pay Summary</h2>
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Salary/Wages</Col>
                    <Col className="text-right">
                      <div key={adminContext.pay.hours}>
                        <MoneyLabel amount={hoursTotals()} />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Leave</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={leaveTotals()} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Leave Loading</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.leave_loading} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Allowances</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.allowance} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Deductions</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.total_deduction} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Bonus/Commission</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.bonus} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Back Payment</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.back_payment} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Sacrifice</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.sacrifice} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Gross</Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.gross} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="2">Tax</Col>
                    <Col xs="1" className="text-right">
                      <CheckBox
                        checked={!adminContext.pay.is_tax_locked}
                        color="primary"
                        name="is_tax_locked"
                        className="mx-0 mt-0 mb-1 py-0 auto-calc-checkbox"
                        disabled={
                          adminContext.pay.employee.locked ||
                          !adminContext.editAccess("pays")
                        }
                        onChange={(e) => {
                          // No need to connect to the backend if we are locking the tax
                          if (e.target.checked) {
                            setSaving(true);
                          }

                          adminContext.setPay({
                            ...adminContext.pay,
                            is_tax_locked: !e.target.checked,
                          });
                        }}
                      />
                    </Col>
                    <Col xs="4" className="text-sm ml-2">
                      (Auto Calc.)
                    </Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.tax} />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="6">Net</Col>
                    <Col className="text-right">
                      <MoneyLabel
                        amount={adminContext.pay.cash_rounded_real_net}
                      />
                    </Col>
                  </Row>
                  <Row className="mx-0 my-3">
                    <Col xs="2">Super</Col>
                    <Col xs="1" className="text-right">
                      <CheckBox
                        name="is_super_locked"
                        checked={!adminContext.pay.is_super_locked}
                        color="primary"
                        disabled={
                          adminContext.pay.employee.locked ||
                          !adminContext.editAccess("pays")
                        }
                        className="mx-0 mt-0 mb-1 py-0 auto-calc-checkbox"
                        onChange={(e) => {
                          // No need to connect to the backend if we are locking the super
                          if (e.target.checked) {
                            setSaving(true);
                          }

                          adminContext.setPay({
                            ...adminContext.pay,
                            is_super_locked: !e.target.checked,
                          });
                        }}
                      />
                    </Col>
                    <Col xs="4" className="text-sm ml-2">
                      (Auto Calc.)
                    </Col>
                    <Col className="text-right">
                      <MoneyLabel amount={adminContext.pay.super} />
                    </Col>
                  </Row>

                  <Row className="my-2">
                    <Col>
                      Advance payment: Spread tax over how many{" "}
                      {adminContext.payRun.pay_period
                        ? adminContext.payRun.pay_period.toLowerCase()
                        : ""}{" "}
                      pays?
                    </Col>
                    <Col>
                      <AvForm>
                        <MultiplierEdit
                          name="leave_paid_in_advance_periods"
                          key="leave_paid_in_advance_periods"
                          strictLabel={true}
                          label=""
                          onBlur={updateBackendPay}
                          value={adminContext.pay.leave_paid_in_advance_periods}
                          setValue={(value) => {
                            adminContext.setPay({
                              ...adminContext.pay,
                              leave_paid_in_advance_periods: value,
                            });
                          }}
                        />
                      </AvForm>
                    </Col>
                  </Row>
                </>
              </Col>
            </Row>
          )}
          {postCompletePromptMessages.length > 0 && (
            <PostCompleteModal
              messages={postCompletePromptMessages}
              currentIndex={currentPostCompleteMessageIndex}
              onNext={handlePostCompleteModalNext}
            />
          )}
        </div>
      )}
    </>
  );
};
export default EditPayView;
